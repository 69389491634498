<template>
  <div class="propertyType">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_item')">
                <el-select
                  v-model.trim="formInline.assetsTypeName"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="item.assetsTypeName"
                    :value="item.assetsTypeName"
                    v-for="item in assetsTypeList"
                    :key="item.assetsTypeName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="clearFrom()"
                :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.add"
          ></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.add">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="$router.push('propertyTypeAdd')"
                :loading="loading"
                v-if="$route.meta.authority.button.add"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70px"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            v-if="
              $route.meta.authority.button.view ||
              $route.meta.authority.button.edit
            "
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="c">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import autoComplete from "@/mycomponents/myautocomplete";
export default {
  name: "propertyType",
  components: {
    // autoComplete
  },
  data() {
    return {
      tenantList: [], // 商户数据
      assetsTypeList: [],
      page: 0,
      pageSize: 10,
      total: 0,
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "assetsTypeName",
          label: this.$t("list.Type_of_item"),
          width: "",
        },
      ],
      loading: false,
      tableData: [],
      formInline: {
        assetsTypeName: "",
        assetsTypeId: "",
        operationId: "",
      },
    };
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    clearFrom() {
      this.formInline.assetsTypeName = "";
      this.formInline.operationId = "";
    },
    getAssetsTypeList() {
      this.$axios
        .get("/acb/2.0/assetsType/allList", {
          data: {
            type: 0,
          },
        })
        .then((res) => {
          this.assetsTypeList = res.value.list;
        });
    },
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.$router.push({
          path: "propertyTypeView",
          query: data,
        });
      } else if (cmd === "b") {
        this.$router.push({
          path: "propertyTypeAdd",
          query: data,
        });
      } else if (cmd === "c") {
        this.$confirm("此操作将删除该记录, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.deleteItem(data);
          })
          .catch(() => {});
      }
    },
    view() {},
    deleteItem(item) {
      this.$axios
        .post("/acb/2.0/assetsType/deleteType/" + item.assetsTypeId, {
          data: {},
        })
        .then((res) => {
          this.searchData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      this.getAssetsTypeList();
      this.$axios
        .get("/acb/2.0/assetsType/list", {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
            type: 0,
          },
        })
        .then((res) => {
          this.tableData = [];
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  mounted() {
    this.getTenantList();
    this.getAssetsTypeList();
    this.searchData();
  },
  activated() {
    // this.getAssetsTypeList()
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped>
.propertyType {
  .content {
    overflow: hidden;

    .searchWrapper {
      overflow: hidden;
      // background: #EFF2F7;
    }

    .pagerWrapper {
      text-align: right;
      margin-top: 18px;
      font-size: 12px;
    }
  }
}
</style>
